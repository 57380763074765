import logo from "./svgs/logo.svg";
import logo2 from "./svgs/logo2.svg";
import play_arrow from "./svgs/play_arrow.svg";
import qr_code from "./svgs/qr_code.svg";
import call_button from "./svgs/callBtn.svg";
import payLogo from "./svgs/pay-attitude-logo.png";
import failed from "./svgs/failed.svg";
import failed2 from "./svgs/failed2.svg";
import checked from "./svgs/checked.svg";
import spin from "./svgs/spin.svg";
import credit_card from "./svgs/credit_card.svg";
import Ussd from "./svgs/Ussd.svg";
import wallet from "./svgs/wallet.svg";
import bankIcon from "./svgs/bankIcon.svg";
import cancel from "./svgs/cancel.svg";
import auth from "./svgs/auth.svg";
import cardSect from "./svgs/cardSect.png";
import logo3 from "./svgs/logo3.svg";
import backLogo from "./svgs/backLogo.svg";
import live_help from "./svgs/live_help.svg";
import lock_icon from "./svgs/lock.svg";
import sponsors_icon from "./svgs/cardSect.png";


export const SVGS = {
  logo,
  play_arrow,
  qr_code,
  call_button,
  payLogo,
  failed,
  checked,
  failed2,
  spin,
  cancel,
  credit_card,
  Ussd,
  wallet,
  bankIcon,
  auth,
  logo2,
  cardSect,
  logo3,
  backLogo,
  live_help,
  lock_icon,
  sponsors_icon
};
