import React, { useState, useEffect } from 'react';
import styles from './success.module.css';

function Success() {
	const [ productDetails, setProductDetails ] = useState(null);
	const [ transID, setTranID ] = useState('');
	const storage = sessionStorage.getItem('product_data');
	const tranId = sessionStorage.getItem('tranId');
	useEffect(() => {
		if (storage) {
			setTranID(tranId);
			setProductDetails(JSON.parse(storage));
		}
	}, []);

	const finishHandler = () => {
		const callback = window.sessionStorage.getItem("fallbackUrl")
        sessionStorage.clear();
		if (typeof(callback) !== 'undefined' && callback != null){
			window.location.href= callback;
		  }
		  else{
			window.history.go(-1);
		  }
	};

	return (
		<div className={styles.main_container}>
			<header class={styles.navbar}>
				<svg width="88" height="37" viewBox="0 0 88 37" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M72.2745 36.63L67.1307 30.841H62.8667C59.5111 30.841 56.7806 28.1242 56.7806 24.7852H59.8364C59.8364 26.4476 61.196 27.8004 62.8667 27.8004H68.5083L72.1042 31.8476L75.2181 27.8004H81.4924C83.1631 27.8004 84.5227 26.4476 84.5227 24.7852V6.05578C84.5227 4.39344 83.1631 3.04063 81.4924 3.04063H62.8667C61.196 3.04063 59.8364 4.39344 59.8364 6.05578H56.7806C56.7806 2.71686 59.5111 0 62.8667 0H81.4932C84.8488 0 87.5793 2.71686 87.5793 6.05578V24.7852C87.5793 28.1242 84.8488 30.841 81.4932 30.841H76.7275L72.2745 36.63Z" fill="#FF6700"/>
					<path d="M0 9.52344H3.27109L4.45021 16.8904L6.16943 9.52344H9.43292L11.1598 16.8904L12.3389 9.52344H15.5947L13.1376 22.7102H9.76003L7.80498 14.4078L5.85754 22.7102H2.47994L0 9.52344Z" fill="#FF6700"/>
					<path d="M23.5899 20.5334H19.6646L19.1245 22.7102H15.6024L19.7939 9.52344H23.5519L27.7434 22.7102H24.1376L23.5899 20.5334ZM22.8672 17.682L21.6349 12.9416L20.4101 17.682H22.8672Z" fill="#FF6700"/>
					<path d="M26.4046 9.52344H30.231L32.4827 13.976L34.7344 9.52344H38.538L34.2019 17.1873V22.7102H30.7483V17.1873L26.4046 9.52344Z" fill="#FF6700"/>
					<path d="M45.0422 20.5334H41.1169L40.5768 22.7102H37.0546L41.2462 9.52344H45.0042L49.1957 22.7102H45.5899L45.0422 20.5334ZM44.3195 17.682L43.0871 12.9416L41.8624 17.682H44.3195Z" fill="#FF6700"/>
					<path d="M50.2911 9.52344H56.0194C57.2669 9.52344 58.2001 9.87425 58.8188 10.5759C59.4426 11.2775 59.7545 12.2759 59.7545 13.5712C59.7545 14.9025 59.4147 15.9429 58.7351 16.6925C58.0606 17.4421 57.0286 17.8169 55.639 17.8169H53.7524V22.7102H50.2911V9.52344ZM53.7524 15.1454H54.5968C55.2612 15.1454 55.7278 15.0104 55.9965 14.7406C56.2653 14.4647 56.3997 14.1139 56.3997 13.6882C56.3997 13.2744 56.2831 12.9236 56.0498 12.6357C55.8165 12.3479 55.3778 12.204 54.7337 12.204H53.7524V15.1454Z" fill="#FF6700"/>
					<path d="M67.103 20.5334H63.1777L62.6376 22.7102H59.1155L63.3071 9.52344H67.065L71.2566 22.7102H67.6508L67.103 20.5334ZM66.3804 17.682L65.148 12.9416L63.9232 17.682H66.3804Z" fill="#FF6700"/>
					<path d="M69.9177 9.52344H73.7441L75.9958 13.976L78.2476 9.52344H82.0512L77.7151 17.1873V22.7102H74.2614V17.1873L69.9177 9.52344Z" fill="#FF6700"/>
				</svg>

			</header>
			<div class={styles.container}>
				<svg
					id={styles.icon}
					width="148"
					height="148"
					viewBox="0 0 173 173"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="86.5987" cy="86.7262" r="86.0406" fill="#F1F7FA" />
					<path d="M56.8117 84.0237L79.286 109.213L119.182 68.6789" stroke="#27AE60" stroke-width="6" />
				</svg>
				<h5 class={styles.title}>Payment Successful</h5>
				<p class={styles.sub_title}>
					You have successfuly Paid NGN <span>{productDetails && productDetails.amount}</span> to{' '}
					<span>{productDetails && productDetails.merchant_business_name}</span>.
				</p>

				<p class={styles.message}>Here’s your transaction reference</p>
				<p class={styles.transaction_id}>
					{transID}
					<span>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21.6818 0.0859375H8.99907C8.09517 0.0859375 7.35986 0.821298 7.35986 1.72515V5.57249H8.85622V1.72515C8.85622 1.64639 8.92032 1.5823 8.99907 1.5823H21.6818C21.7606 1.5823 21.8247 1.64639 21.8247 1.72515V14.4079C21.8247 14.4867 21.7606 14.5508 21.6818 14.5508H17.9544V16.0471H21.6818C22.5857 16.0471 23.321 15.3118 23.321 14.4079V1.72515C23.321 0.821298 22.5857 0.0859375 21.6818 0.0859375Z"
								fill="#C4C4C4"
							/>
							<path
								d="M14.8185 7.06885H2.13584C1.23194 7.06885 0.496582 7.80421 0.496582 8.70811V21.3908C0.496632 22.2947 1.23194 23.0301 2.13584 23.0301H14.8186C15.7225 23.0301 16.4578 22.2947 16.4578 21.3909V8.70811C16.4579 7.80421 15.7224 7.06885 14.8185 7.06885ZM14.8186 21.5337H2.13584C2.05703 21.5337 1.99294 21.4697 1.99294 21.3909V8.70811C1.99294 8.6293 2.05703 8.56521 2.13584 8.56521H14.8186C14.8974 8.56521 14.9615 8.6293 14.9615 8.70811V21.3909H14.9615C14.9615 21.4697 14.8974 21.5337 14.8186 21.5337Z"
								fill="#C4C4C4"
							/>
						</svg>
					</span>
				</p>

				<button class={styles.goto_btn} onClick={finishHandler}>
					Okay
				</button>
			</div>
		</div>
	);
}

export default Success;
