import React from 'react';
import {FormBody, FormHead} from './main.js';
import bgImage from '../../assets/background/pattern-bg.png';

const bodyStyle = {
    height: '100vh',
    background: 'linear-gradient(to bottom, #fff1f1de, #fcf3eacc), url('+bgImage+')',
}
function Index() {
    return (
        <div style={bodyStyle}>
            <FormHead />
            <FormBody />
        </div>
    )
}

export default Index
