import React from "react";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./container.module.css";
import { InputText } from "../inputs/inputs";
import { SVGS } from "../../assets/svgs";
import { LightText } from "../lightText/lighttext";
import { ModalWrapper } from "../modalWrapper/wrapper";

const { cancel } = SVGS;
export const ModalContainer = ({ children, cancelbtn = true,oncancel }) => {
  return (
    <ModalWrapper>
      <div className={`${styles.container}`}>
        {children}
        {cancelbtn && (
          <div onClick={oncancel} className={`${styles.cancel}`}>
            <img src={cancel} width="35" alt="" />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
