import React, { useState, useEffect } from "react";
import {
  CardContainer,
  SideCardContainer,
  SideCardcontent,
} from "../all-containers/general";
import { Background } from "../background/background";
import { CardContents } from "../card/cardcontainer";
import { SectionItem } from "../section-item/sectionItem";
import styles from "./initpay.module.css";
import { Title } from "../titles/titles";
import { Ussd } from "../ussd/ussd";
import { Bank } from "../bank/bank";
import { Wallets } from "../wallet/wallet";
import { PayAttitude } from "../payAttitude/pay";
import { ModalContainer } from "../modalContainer/container";
import {
  InProccess,
  OTPOverlay,
  PaymentFailed,
  PaymentSuccess,
  PlsWait,
} from "../modal/container";
import { SVGS } from "../../assets/svgs";
import { NormalText } from "../normalText/normalText";
import { useDispatch, useSelector } from "react-redux";
import { Dispatach_New_Tab } from "../../redux/actions/account";
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import config from '../../config.js';
// let CryptoJS = require("crypto-js");
import logo3 from '../../assets/quickLogo.png'


const {
  Ussd: USSD,
  bankIcon,
  credit_card,
  wallet,
  call_button,
  cardSect,
  // logo3,
  backLogo
} = SVGS;


export const Initpay = () => {
  const navigate = useNavigate();
  // const [active, setActive] = useState("Card");
  const [modals, setModals] = useState("");
  const [tabs, setTabs] = useState(1);
  const active = useSelector(state => state.tabs)
  const Dispatch = useDispatch();
  const [companyName, setCompanyName] = useState(null);
  const [menus, setMenus] = useState([]);
  const [searchParams] = useSearchParams();
  const [tranIdd, setTranId] = useState(null);

  useEffect(() => {
    console.log("Initialising payment....");

    const tranId = searchParams.get("_tranId");
    const pubKey = searchParams.get("_pubkey");
    const callbackUrl = document.referrer + '?ref='+tranId;

    let appStorage = sessionStorage;
    appStorage.setItem("fallbackUrl", callbackUrl);
    
    if (tranId) {
      if (sessionStorage.getItem("product_data")) {
        navigate(`/?_tranId=${tranId}`);
      }

      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/reference/query/${tranId}`;
      axios.get(ENDPOINT).then(response => {
        //console.log(response);
        if (response.data.data.Status === "PENDING") {
          let storage = sessionStorage;
          storage.setItem("tranId", tranId);
          storage.setItem("product_data", JSON.stringify({
            amount: response.data.data.Amount,
            description: response.data.data.Description,
            fee: response.data.data.Fee,
            customer: { name: response.data.data.customer.name, email: response.data.data.customer.email, phoneNumber: response.data.data.customer.phoneNumber, customerId: response.data.data.customer.customerId, merchantId: response.data.data.merchantId },
            product_name: response.data.data.productName,
            merchant_business_name: response.data.data.businessName,
            recurrentPayment: response?.data?.data?.recurrentPayment,
          }));
          sessionStorage.setItem("PUBLIC_KEY", pubKey);
        } else {
          if (typeof(callbackUrl) !== 'undefined' && callbackUrl != null){
            window.location.href= callbackUrl;
          }
          else{
            window.history.go(-1);
          }
        }
      }).catch(err => {
        //console.log(err);
        if (typeof(callbackUrl) !== 'undefined' && callbackUrl != null){
          window.location.href= callbackUrl;
        }
        else{
          window.history.go(-1);
        }
      });


      navigate(`/?_tranId=${tranId}`);
    }
    else {
      if (typeof(callbackUrl) !== 'undefined' && callbackUrl != null){
        window.location.href= callbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }

  });

  return (
    <CardContainer></CardContainer>
  );

};