import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { paymentDataReducer } from "../reducers/card";
import { NavigationReducer } from "../reducers/navigations";
import { tabsReducer } from "../reducers/tabs";


//import logger from "redux-logger";

const reducers = combineReducers({
  tabs:tabsReducer,
  card:paymentDataReducer,
  navigation:NavigationReducer
});

let middleware = [thunk];

export const store = createStore(reducers, {}, applyMiddleware(...middleware));
