import React from "react";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./container.module.css";
import { InputText } from "../inputs/inputs";
import { SVGS } from "../../assets/svgs";
import { LightText } from "../lightText/lighttext";
import { ModalWrapper } from "../modalWrapper/wrapper";
import { ModalContainer } from "../modalContainer/container";
import { CancelButton } from "../button/button";
import { IconWrapper } from "../iconWrapper/wrapper";
import { BiCopy } from "react-icons/bi";

const { logo, failed, checked, failed2, spin } = SVGS;

const style = {
  otpField: {
    width: "100%",
    height: "42px",
    paddingLeft: "8px",
    border: "1p solid #999999",
    outline: "none"
  }
}

export const OTPOverlay = ({ cancel }) => {
  return (
    <ModalContainer oncancel={cancel} >
      <div className={`${styles.container}`}>
        <img src={failed} alt="" />
      </div>
      <div>
        <BoldText align="center">OTP Confirmation.</BoldText>
        <div className={styles.margin}>
          <NormalText align="center">OTP has been sent to your phone number attached to your bank.</NormalText>
        </div>
      </div>
      <div className={styles.margin}>
        <input type="number" style={style.otpField} />
        <CancelButton text="Continue OTP" onclick={cancel} />
      </div>
    </ModalContainer>
  );
};

export const InProccess = ({ cancel }) => {
  return (
    <ModalContainer oncancel={cancel} >
      <div className={`${styles.container}`}>
        <img src={failed} alt="" />
      </div>
      <div>
        <BoldText align="center">Payment in process.</BoldText>
        <div className={styles.margin}>
          <NormalText align="center">Are you sure you want to cancel this payment?</NormalText>
        </div>
      </div>
      <div className={styles.margin}>
        <CancelButton text="Yes cancel this payment" onclick={cancel} />
        <CancelButton text="No I want to continue" onclick={cancel} />
      </div>
    </ModalContainer>
  );
};

export const PaymentSuccess = ({}) => {
  return (
    <ModalContainer cancelbtn={false}>
      <div className={`${styles.container}`}>
        <img src={checked} alt="" />
      </div>
      <div>
        <BoldText align="center" size={18}>
          Payment Successful
        </BoldText>
        <div className={styles.margin}>
          <NormalText align="center">
            You Paid NGN 5,000 to {"{Merchant-Biz-Name}"} for the purchase/order
            of {"{product-name}"}
          </NormalText>
        </div>
        <div className={styles.margin}>
          <NormalText align="center">
            Here’s your transaction reference
          </NormalText>
          <BoldText align="center">
            T987934HDF849848{" "}
            <span>
              <BiCopy />
            </span>
          </BoldText>
        </div>
      </div>
      <div className={styles.margin2}>
        <CancelButton text="okay" />
      </div>
    </ModalContainer>
  );
};

export const PaymentFailed = ({}) => {
  return (
    <ModalContainer cancelbtn={false}>
      <div className={`${styles.container}`}>
        <img src={failed2} alt="" />
      </div>
      <div>
        <BoldText align="center" size={18}>
          Payment Failed!
        </BoldText>
        <div className={styles.margin3}>
          <NormalText align="center">
            Error occured while processing your payment, kindly try again or
            choose a different payment method.
          </NormalText>
        </div>
      </div>
      <div className={styles.margin2}>
        <CancelButton text="Start Over" />
      </div>
    </ModalContainer>
  );
};

export const PlsWait = ({}) => {
  return (
    <ModalContainer>
      <div className={`${styles.container}`}>
        <img src={spin} alt="" />
      </div>
      <div className={`${styles.margin3}`}>
        <BoldText align="center" size={18}>
          Please wait while we’re processing your payment
        </BoldText>
      </div>
    </ModalContainer>
  );
};
