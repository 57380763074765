import React, { useState, useEffect } from 'react';
import styles from './paymentLink.module.css';
import WayapayLogo  from '../../assets/quickLogo.png';
import { SVGS } from '../../assets/svgs';
import Failed from '../../assets/svgs/failed.svg';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.js';
import toast, { Toaster } from 'react-hot-toast'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, CancelButton } from "../../components/button/button";

const phoneRegExp = /^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/
const SignupSchema = Yup.object().shape({
	firstname: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	lastname: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required')
});

export const PaymentLink = () => {
	const navigate = useNavigate();
	const [ searchParams ] = useSearchParams();
	// const [ businessLogo, setBusinessLogo ] = useState('');
	// const [ firstname, setFirstname ] = useState('');
	// const [ lastname, setLastname ] = useState('');
	// const [ email, setEmail ] = useState('');
	// const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ errMsg, setErrMsg ] = useState('');
	const [paymentLinkDetails, setPaymentLinkDetails] = useState([]);
	const [ systemInfo, setSystemInfo ] = useState(null);
	const [publicKey, setpublicKey] = useState("");
	const [callbackUrl, setCallbackUrl] = useState("");
	const [businessData, setBusinessData] = useState(null);

	useEffect(() => {
		var _navigator = {};
		for (var i in navigator) _navigator[i] = navigator[i];
		setSystemInfo(() => _navigator);

		const paymentLinkID = searchParams.get('paymentLinkId');
		const publicKey = searchParams.get('publicKey');
		const merchantId = searchParams.get('merchantId');
		const businessName = searchParams.get("businessName")
		const businessLogo = searchParams.get("logoUrl")
		setBusinessData({
			businessName,businessLogo
		})
		setpublicKey(publicKey);
		const ENDPOINT = `${config.API_ROOT}/identity-manager-service/api/v1/webpos/payment-link/no-auth/fetch/${paymentLinkID}`;
		axios.get(ENDPOINT).then((response) => {
			setPaymentLinkDetails(response.data.data);
		});

		axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/fetch/callback?param=${merchantId}`).then((result) => {
			setCallbackUrl(result.data.data.webCallbackUrl);
        }).catch((err) => {
            //console.log(err.response)
		});
		// axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/${merchantId}`).then((result) => {
		// 	// setCallbackUrl(result.data.data.webCallbackUrl);
        // }).catch((err) => {
        //     //console.log(err.response)
		// });
	}, []);

	// paymentLinkDetails
	const InitialiseTransaction = (values) => {
		const { firstname, lastname, email, phoneNumber } = values;
		setLoading(true);
		const transactionData = {
			amount: paymentLinkDetails?.payableAmount,
			description: paymentLinkDetails?.description,
			currency: paymentLinkDetails?.currency,
			fee: '0',
			deviceInformation: JSON.stringify(systemInfo),
			customer: {
				name: `${firstname} ${lastname}`,
				email: email,
				phoneNumber: phoneNumber
			},
			merchantId: paymentLinkDetails?.merchantId,
			paymentLinkId: paymentLinkDetails?.paymentLinkId,
			wayaPublicKey: publicKey,
			recurrentPayment: paymentLinkDetails?.paymentLinkType==="SUBSCRIPTION_PAYMENT_LINK" ? true : false,

		};

		const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/request/transaction`;
		const header = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		if(publicKey && paymentLinkDetails?.merchantId && firstname && lastname && phoneNumber && paymentLinkDetails?.payableAmount){
			if (email) {
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
					axios.post(API_ENDPOINT, transactionData, header).then((response) => {
						// toast.
						axios.get(`${config.API_ROOT_ADDRESS}/api/v1/reference/query/${response.data.data.tranId}`, header).then((response2) => {
							const storage = window.sessionStorage;
							storage.setItem('tranId', response.data.data.tranId);
							storage.setItem('fallbackUrl', callbackUrl);

							storage.setItem(
								'product_data',
								JSON.stringify({
									amount: paymentLinkDetails?.payableAmount,
									description: paymentLinkDetails?.description,
									fee: 0,
									customer: {
										name: `${firstname} ${lastname}`,
										email: email,
										phoneNumber: phoneNumber,
										customerId: response.data.data.customerId,
										merchantId: response2?.data?.data?.merchantId,
									},
									product_name: response2.data.data.productName,
									merchant_business_name: response.data.data.name,
									paymentLinkType: paymentLinkDetails?.paymentLinkType,
									recurrentPayment: response2?.data?.data?.recurrentPayment,
									merchantId: response2?.data?.data?.merchantId
								})
							);
							
							const transactionSource = "linkPaymentPage"
							// SUBSCRIPTION_PAYMENT_LINK - Recurrent payment link
							// CUSTOMER_SUBSCRIPTION_PAYMENT_LINK - One time payment link
							const transactionType = (paymentLinkDetails?.isSubscriptionPaymentLink || paymentLinkDetails?.paymentLinkType==="SUBSCRIPTION_PAYMENT_LINK") ? "recurrent" : "onetime";

							storage.setItem("transactionSource", JSON.stringify({
								type: transactionType,
								value: transactionSource
							}));
							sessionStorage.setItem("PUBLIC_KEY", publicKey);
							setLoading(false);
							toast.success("Sucessful")
							// window.location.href= config.SITE_URL;
							navigate('/');						
						}).catch((err) => {
						setErrMsg(err.response.data.message);
						setLoading(false);
					});
					}).catch((err) => {
						setErrMsg(err?.response?.data?.message);
						toast.error(err.response.data.message);
						setLoading(false);
					});
				} else {
					setErrMsg('Invalid email.');
					setLoading(false);
				}
			} else {
				toast.error('Email field is required.');
				setLoading(false);
			}
		}else{
			toast.error('All fields required');
			setLoading(false);
		}
	};

	return (
		<>
			<Toaster position="top-right" />
			<div className={styles.container}>
				{
					!paymentLinkDetails && (
						<div className={styles.overlay}>
							<div className={styles.overlayInner}>
								<img src={Failed} alt="#" />
								<h4 className={styles.title}>The payment link is not valid</h4>
								<p>You are adviced to retry if you are sure the payment link is correct.</p>
								<button onClick={()=> window.location.reload()} className={styles.button2}>Retry</button>
							</div>
						</div>
					)
				}
				<nav className={styles.navbar}>
					<img src={WayapayLogo} width='150px'/>
				</nav>
				<div className={styles.profileContainer}>
					{businessData?.businessLogo ? <img src={businessData?.businessLogo} alt="business-logo" width="72px" height="72px" /> : ""}
					{
						paymentLinkDetails ?.paymentLinkType !== "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK" 
						?
						<h5 className={styles.h5}>Subscribe to plan: {paymentLinkDetails ?.paymentLinkName}</h5> 
						: 
						(
							<>
							{
								paymentLinkDetails?.isSubscriptionPaymentLink ? <h5 className={styles.h5}>Subscribe to plan: {paymentLinkDetails ?.paymentLinkName}</h5> : <h5 className={styles.h5}> {paymentLinkDetails ?.paymentLinkName}</h5>
							}
							</>
						)
					}
					<p className={styles.p}>BY {businessData?.businessName}</p>
					{
						paymentLinkDetails ?.paymentLinkType !== "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK" ?
							(<h5 className={styles.title}>YOU WILL BE CHARGED {paymentLinkDetails?.totalCount} {paymentLinkDetails?.intervalType} PAYMENTS OF NGN{paymentLinkDetails?.payableAmount} EACH</h5>) : (
								paymentLinkDetails?.isSubscriptionPaymentLink && (<h5 className={styles.title}>YOU WILL BE CHARGED {paymentLinkDetails?.totalCount} {paymentLinkDetails?.intervalType} PAYMENTS OF NGN{paymentLinkDetails?.payableAmount} EACH</h5>)
							)
					}
				</div>
				<div className={styles.formContainer}>
					<Formik 
						initialValues={{
							firstname: '',
							lastname: '',
							email: '',
							phoneNumber: ''
						}}
						validationSchema={SignupSchema}
						onSubmit={InitialiseTransaction}
					>
					{({ errors, touched }) => (
						<Form>
							<div className={styles.form2Group}>
								<div className={styles.inputWrap}>
									<label className={styles.label}>First Name</label>
									<Field name='firstname' type="text" className={styles.input} placeholder="Blessing." />
									{errors.firstname && touched.firstname ? ( <small className={styles.error}>{errors.firstname}</small> ) : null}
								</div>
								<div className={styles.inputWrap}>
									<label className={styles.label}>Last Name</label>
									<Field name='lastname' type="text" className={styles.input} placeholder="Ladejobi" />
									{errors.lastname && touched.lastname ? ( <small className={styles.error}>{errors.lastname}</small> ) : null}
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={styles.inputWrap}>
									<label className={styles.label}>Email Address</label>
									<Field name='email' type="email" className={styles.input} placeholder="blessing.ladejobi@wayapaychat.com" />
									{errors.email && touched.email ? ( <small className={styles.error}>{errors.email}</small> ) : null}
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={styles.inputWrap}>
									<label className={styles.label}>Phone number</label>
									<Field name='phoneNumber' type="text" className={styles.input} placeholder="+2348148331698" />
									{errors.phoneNumber && touched.phoneNumber ? ( <small className={styles.error}>{errors.phoneNumber}</small> ) : null}
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={styles.inputWrap}>
									<label className={styles.label}>Amount to charge(NGN)</label>
									<input type="number" value={paymentLinkDetails?.payableAmount} readOnly={true} className={styles.input} placeholder="500,000" />
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={styles.inputWrap}>
									<Button className={styles.button} type='submit' loading={loading} text={
										paymentLinkDetails ?.paymentLinkType !== "CUSTOMER_SUBSCRIPTION_PAYMENT_LINK" ? "Pay now and subscribe": (
											<>
												{paymentLinkDetails?.isSubscriptionPaymentLink && "Pay now and subscribe"}
												{!paymentLinkDetails?.isSubscriptionPaymentLink && "Pay now"}
											</>
										)}></Button>
								</div>
							</div>

							<div className={styles.formGroup}>
								<div className={styles.inputWrap}>
									<fieldset className={styles.fieldset}>
										<legend align="center">
											<img src={SVGS.lock_icon} /> Payment secured by WayaQuick
										</legend>
										<img src={SVGS.sponsors_icon} />
									</fieldset>
								</div>
							</div>

							<p className={styles.h6}>
								If you have any questions, contact{' '}
								<a href="mailto:info@wayaquick.com">info@WayaQuick.com</a>
							</p>
					</Form>
					)}
					</Formik>
				</div>
			</div>
		</>
	);
};
