import React, {useState} from 'react';
import styles from './demo.module.css';

function InputField({label, type, inputValue, placeholder, format, onChange}) {
    const [inputVal, setInputVal] = useState(inputValue);
    const [amount] = useState(inputValue);

    const formatInput = (e) => {
        const inputVal = e.target.value.replace(/ /g, "");
        let inputNumbersOnly = inputVal.replace(/\D/g, "");
        if (inputNumbersOnly.length > 16) {
            inputNumbersOnly = inputNumbersOnly.substr(0, 16);
        }
        const spacedNumber = inputNumbersOnly.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        setInputVal(spacedNumber)
    }
    return (
        <div className={`${styles.container}`}>
            <span className={styles.inline_label}>{label}</span>
            <div className={styles.input_container}>
                <input
                    type={type}
                    onInput={format ? formatInput : (e)=>setInputVal(e.target.value)}
                    onChange={format ? console.log() : (e)=> onChange(e)}
                    id="thisInput"
                    value={format ? amount : inputVal}
                    className={`${styles.input}`}
                    placeholder={placeholder}
                    
                />
            </div>
        </div>
    )
}

export default InputField

