import React, { useState } from "react";
import { SVGS } from "../../assets/svgs";
import { Button, CancelButton } from "../button/button";
import { ContentsContainer } from "../card/cardcontainer";
import { IconWrapper } from "../iconWrapper/wrapper";
import { InputDate, InputSelect, InputText } from "../inputs/inputs";
import { LightText } from "../lightText/lighttext";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./bank.module.css";

const { bankIcon,Ussd } = SVGS;
export const Bank = ({ title }) => {
  const [tabs, setTabs] = useState(1);

  return (
    <ContentsContainer>
      {tabs === 1 && (
        <div className={styles.contentsBetween}>
          <div className={styles.text}>
            <div style={{ marginBottom: 10 }}>
              <IconWrapper pad={20}>
                <img src={bankIcon} alt="" />
              </IconWrapper>
            </div>

            <BoldText align="center" size={14}>
              Select your bank provider to make payment
            </BoldText>
          </div>
          <div className={styles.inputContainer2}>
            <InputSelect code={false} />
            <InputText placeholder="Bank Account Number (10 Digits)" />
          </div>
          <div className={styles.buttonContainer}>
            <Button text="Confirm Account" active={true} onclick={()=>setTabs(2)} />
          </div>
        </div>
      )}
      {tabs === 2 && (
        <div className={styles.contentsBetween}>
          <div className={styles.text}>
          <div style={{ marginBottom: 10 }}>
              <IconWrapper pad={20}>
                <img src={Ussd} alt="" />
              </IconWrapper>
            </div>
            <BoldText align="center" size={14}>
              Please enter your BVN
            </BoldText>
          </div>
          <div className={styles.inputContainer2}>
            <InputText placeholder="Bank Account Number (10 Digits)" />
          </div>
          <div className={styles.buttonContainer2}>
            <Button text="Authorize Payment" active={true} onclick={()=>setTabs(3)} />
            <CancelButton text="cancel" onclick={()=>setTabs(1)} />
          </div>
        </div>
      )}
      {tabs === 3 && (
        <div className={styles.contentsBetween}>
          <div className={styles.text}>
          <div style={{ marginBottom: 10 }}>
              <IconWrapper pad={20}>
                <img src={bankIcon} alt="" />
              </IconWrapper>
            </div>
            <BoldText align="center" size={14}>
              Enter your date of birth
            </BoldText>
          </div>
          <div>
            <NormalText align="center" size={14}>
              Kindly ensure your date of birth match with the one on your BVN
            </NormalText>
          </div>
          <div className={styles.inputContainer3}>
            <InputSelect width={100} code={false} />
            <InputDate width={50} />
            <InputDate width={100} />
          </div>
          <div className={styles.buttonContainer2}>
            <Button text="Authorize Payment" active={true} onclick={()=>setTabs(4)} />
            <CancelButton text="cancel" onclick={()=>setTabs(1)} />
          </div>
        </div>
      )}
      {tabs === 4 && (
        <div className={styles.contentsBetween}>
          <div className={styles.text}>
          <div style={{ marginBottom: 10 }}>
              <IconWrapper pad={20}>
                <img src={Ussd} alt="" />
              </IconWrapper>
            </div>
            <BoldText align="center" size={14}>
              To confirm that you own this account, kindly enter the OTP sent to
              your phone registered with your BVN
            </BoldText>
          </div>
          <div className={styles.inputContainer4}>
            <InputText
              under={true}
              underText={`You should receive a token code in less than 5 mins`}
              placeholder="Enter OTP here"
            />
          </div>
          <div className={styles.buttonContainer2}>
            <Button text="Authorize Payment" active={true} onclick={()=>setTabs(1)} />
            <CancelButton text="cancel" onclick={()=>setTabs(1)} />
          </div>
        </div>
      )}{" "}
    </ContentsContainer>
  );
};
