import {
  CARD_PIN,
  ITEM_CARD_EXPIRYDATE,
  ITEM_CARD_CVV,
  ITEM_CARD_HOOLDERS_NAME,
  ITEM_CARD_NUMBER,
} from "../store/types";

export const Dispatach_Add_Pin = (dispatch, payload) => {
  dispatch({
    type: CARD_PIN,
    payload: payload,
  });
};

export const Dispatach_All_Card_Details = (dispatch,action, payload) => {
  dispatch({
    type: action,
    payload: payload,
  });
};
