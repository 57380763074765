// const MODE = 'staging';
let MODE = 'live';

const staging = {
	API_ROOT_ADDRESS: 'https://services.staging.wayapay.ng/payment-gateway',
	API_ROOT_ADDRESS2: 'https://services.staging.wayapay.ng/auth-service',
	DEMO_URL: 'https://pay.staging.wayapay.ng/demo',
	API_ROOT: 'https://services.staging.wayapay.ng',
	CUS_NAME: 'Ladejobi Blessing',
	CUS_TEL: '08148331698',
	DESCRIPTION: 'Description wayapay demo staging account.',
	PRODUCT_NAME: 'Wayapay Demo account',
	SITE_URL: 'https://pay.staging.wayapay.ng',
	WAYA_PUB_KEY: 'WAYAPUBK_TEST_0x3442f06c8fa6454e90c5b1a518758c70',
	MERCH_ID: 'MER_qZaVZ1645265780823HOaZW'
};

const live = {
	API_ROOT_ADDRESS: 'https://services.wayapay.ng/payment-gateway',
	API_ROOT_ADDRESS2: 'https://services.wayapay.ng/auth-service',
	DEMO_URL: 'https://pay.wayapay.ng/demo',
	API_ROOT: 'https://services.wayapay.ng',
	CUS_NAME: 'Ladejobi Blessing',
	CUS_TEL: '08148331698',
	DESCRIPTION: 'Description wayapay demo account.',
	PRODUCT_NAME: 'Wayapay Demo account',
	SITE_URL: 'https://pay.wayapay.ng',
	WAYA_PUB_KEY: 'WAYAPUBK_PROD_0x271b51f9ec964a59a4438ddf2f71cea0',
	MERCH_ID: 'MER_3zUWo1656418606145pYewf'
};

const USERMODE = MODE === 'live' ? live : staging;

export default USERMODE;
