import React from "react";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./wrapper.module.css";
import { InputText } from "../inputs/inputs";
import { SVGS } from "../../assets/svgs";
import { LightText } from "../lightText/lighttext";

export const ModalWrapper = ({ children }) => {
  return <div className={`${styles.container}`}>{children}</div>;
};
