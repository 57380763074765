import './App.css';
import { Container } from './components/container/container';
import { Initpay } from './components/initpay/initpay';
import Demo from './pages/demo/index';
import {PaymentLink} from './pages/payment/paymentLink';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TransactionAuth from './utils/transactionAuth';

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<Container />} />
					<Route path="/start" element={<Initpay />} />
					<Route path="/demo" element={<Demo />} />
					<Route path="/payment-link" element={<PaymentLink />} />
					<Route path="/status" element={<TransactionAuth />} />
					<Route path="/*" element={<Navigate to="/demo" />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
