import React from "react";
import styles from "./section.module.css";

export const SectionItem = ({
  children,
  title,
  description,
  active,
  setActive,
}) => {
  const HandleClick = () => {
    setActive(title);
  };
  return (
    <div
      onClick={HandleClick}
      style={{ backgroundColor: active === title && "#fcfcf9fc" }}
      className={`${styles.SectionItem} ${active === title ? styles.active : ''}`}
    >
      <div className={`${styles.icon}`}>{children}</div>
      <div className={`${styles.names}`}>
        <div className={`${styles.title}`}>{title}</div>
        <div className={`${styles.description}`}>{description}</div>
      </div>
    </div>
  );
};
