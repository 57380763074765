import { CURRENT_TAB, CARD_NAVIGATION } from "../store/types";

const INITIAL_STATE = { card: { step: 1 } };

export const NavigationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARD_NAVIGATION:
      return {...state,card:{...state.card,step:action.payload}};
    default:
      return state;
  }
};
