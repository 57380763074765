import React, { useState, useEffect } from 'react';
import styles from './demo.module.css';
import InputField from './InputField';
// import InputField2 from './InputField2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.js';
import Button from './Button';

function FormBody() {
    const navigate = useNavigate();
    const [amount] = useState(100);
    const [errMsg, setErrMsg] = useState(null);
    const [systemInfo, setSystemInfo] = useState(null);
    // const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        var _navigator = {};
        for (var i in navigator) _navigator[i] = navigator[i];
        setSystemInfo(()=> _navigator);
    }, []);
    
    const SetEmail = (e) => {
        if (e.target.value.length > 0) {
            setErrMsg(null);
        }
        setEmail(e.target.value);
    }

    const InitialiseTransaction = () => {
        setLoading(true);
        const transactionData = {
            amount: amount,
            description: config.DESCRIPTION,
            currency: 'NGN',
            fee: 0,
            deviceInformation: JSON.stringify(systemInfo),
            customer: { name: config.CUS_NAME, email: email, phoneNumber: config.CUS_TEL },
            merchantId: config.MERCH_ID,
            wayaPublicKey: config.WAYA_PUB_KEY
        }
        
        const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/request/transaction`;
        const header = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (email) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                axios.post(API_ENDPOINT, transactionData, header).then(response => {
                    const storage = window.sessionStorage;
                    storage.setItem("tranId", response.data.data.tranId);
                    storage.setItem("product_data", JSON.stringify({
                        amount: amount,
                        description: config.PRODUCT_NAME,
                        fee: 0,
                        customer: { name: config.CUS_NAME, email: email, phoneNumber: config.CUS_TEL,customerId: response.data.data.customerId,merchantId: config.MERCH_ID },
                        product_name: config.PRODUCT_NAME,
                        merchant_business_name: response.data.data.name
                    }));
                    sessionStorage.setItem("PUBLIC_KEY", config.WAYA_PUB_KEY);
                    setLoading(false);
                    navigate("/");
                }).catch(err => {
                    setErrMsg(err.response.data.message);
                    setLoading(false);
                });
            } else {
                setErrMsg("Invalid email.");
                setLoading(false);
            }
        } else {
            setErrMsg("Email field is required.");
            setLoading(false);
        }
    }
    return (
        <div className={styles.formBody}>
            <div className={styles.inputGroup2}>
                <InputField label="Email Address" onChange={SetEmail} inputValue={email} />
                <InputField label="Amount(₦)" format={true} inputValue={amount} />
            </div>
            {/* <div className={styles.inputGroup}>
                <InputField2 label="Product Description" onChange={SetDescription} inputValue={description} />
            </div> */}
            <p style={{
                color: '#eeeeee',
                textAlign: 'left',
                width: '100%',
                paddingLeft: '24px',
                fontSize: '13.33px'
            }} className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
            >
                <span>
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg>
                </span>
                <span className="text-primary-theme ml-2 text-xs">{errMsg}</span>
            </p>

            <div className={styles.inputGroup2}>
                <Button
                    loading={loading}
                    amount={amount}
                    active={amount > 0 ? true : false}
                    onClick={amount > 0 ? InitialiseTransaction : console.log() }
                />
            </div>
        </div>
    )
}

export default FormBody
