import React, { useState, useEffect } from "react";
import { SVGS } from "../../assets/svgs";
import { Button, CancelButton } from "../button/button";
import { ContentsContainer } from "../card/cardcontainer";
import { IconWrapper } from "../iconWrapper/wrapper";
import { InputText, InputText2, InputPhone } from "../inputs/inputs";
import { LightText } from "../lightText/lighttext";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./pay.module.css";
import config from '../../config.js';
import axios from 'axios';
import Spinner from "../Loading/Spinner";
import { useNavigate } from "react-router-dom";

const { call_button } = SVGS;

export const PayAttitude = ({ title }) => {
  const [btnActive, setBtnActive] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [encrytedData, setEncrytedData] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);

  const navigate = useNavigate();
  const handleText = () => {
    setBtnActive(true);
  };

  const SetMobile = (e) => {
    setMobile(e.target.value.substring(0, 11));
    //console.log(e.target.value.substring(0, 11));
  }

  useEffect(() => {
    var _navigator = {};
    for (var i in navigator) _navigator[i] = navigator[i];
    setSystemInfo(()=> _navigator);

    if(sessionStorage.getItem("expired")){
      const fallbackUrl = sessionStorage.getItem("fallbackUrl");
      sessionStorage.clear();
      if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
        window.location.href= fallbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }
}, []);

  const PayWithPayAttitude = () => {
    setLoading(true);
    const paymentData = {
      cardholder: '',
      encryptCardNo: '',
      expiry: '',
      mobile: mobile,
      pin: '',
      deviceInformation: JSON.stringify(systemInfo),
      scheme: 'payattitude',
      tranId: sessionStorage.getItem("tranId"),
      wayaPublicKey: config.WAYA_PUB_KEY
    }

    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/payment`;
    axios.post(API_ENDPOINT, paymentData).then(response => {
      
      setEncrytedData(response.data?.body?.data);
      setLoading(false);
      setTab(2);
      const tranId = sessionStorage.getItem("tranId");
      const trxnData = {
        cardEncrypt: response.data?.body?.data || "", 
        tranId: tranId
      }
      const API_ENDPOINT2 = `${config.API_ROOT_ADDRESS}/api/v1/transaction/processing/bank`;
      axios.post(API_ENDPOINT2, trxnData).then(response2 => {
        setTransactionStatus(response2.data.data.Status);
        // tran ID expired
          sessionStorage.setItem("expired", true);
        if (response2.data.data.Status === "APPROVED") {
          setTab(3);
          sessionStorage.clear();
          setErrMsg(null);
        } else {
          setTab(4);
          sessionStorage.clear();
        }
        // setTimeout(() => {
        //   window.location.href = response.data.data.callbackUrl;
        // }, 300);
      }).catch(error => {
        setErrMsg(error.response.data.message);
        setTab(4);
        sessionStorage.clear();
        setLoading(false);
      })
      //console.log(paymentData);
    }).catch(error => {
      //console.log(error);
      setErrMsg(error.response.data.message);
      setLoading(false);
    })
  }
  const checkPaymentStatus = () => {
    if (transactionStatus) {
      // setTab(3);
      const tranId = sessionStorage.getItem("tranId");
      // const payload = {
      //   status: "SUCCESSFUL"
      // }
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
      axios.put(ENDPOINT, null).then(response => {
        // //console.log(response);
        navigate("/status");
      });
    } else {
      // setTab(4);
      setErrMsg("Payment not completed");
      const tranId = sessionStorage.getItem("tranId");
      // const payload = {
      //   status: "FAILED"
      // }
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
      axios.put(ENDPOINT, null).then(response => {
        // //console.log(response);
        navigate("/status");
      });
    }
  }

  const closePopup = () => {
    const callback = window.sessionStorage.getItem("fallbackUrl")
    sessionStorage.clear();
    if (typeof(callback) !== 'undefined' && callback != null){
      window.location.href= callback;
    }
    else{
      window.history.go(-1);
    }
  }
  return (
    <ContentsContainer>
      {
        tab === 1 && (
          <div className={styles.contents2}>
            <div className={styles.iconContainer}>
              <IconWrapper pad={20}>
                <img height="30" width="30" src={call_button} alt="" />
              </IconWrapper>
              <div style={{ marginTop: 10 }}>
                <BoldText size={14}>
                  Enter your phone number to complete payment
            </BoldText>
              </div>
            </div>
            <div className={styles.nextContainer}>
              <InputPhone label="Phone number" placeholder="08148331698" onchange={handleText} pay={true} onInput={SetMobile} />
              <Button loading={loading} active={btnActive} text="Pay now" onClick={PayWithPayAttitude} />
              <div style={{ margin: 10 }}>
                <LightText>
                  After clicking the pay now button, kindly check your mobile phone to complete payment.
            </LightText>
              </div>
            </div>
          </div>
        )
      }{
        tab === 2 && (
          <div className={styles.waitLoading}>
            <Spinner />
            <p className={styles.text_sm2}>After clicking the pay now button, kindly check your mobile phone to complete payment</p>
            <button className={styles.auth_btn} onClick={checkPaymentStatus}>I have authrorized this payment</button>
          </div>
        )
      }{
        tab === 3 && (
          <div>
            <div>
              <svg width="100" height="100" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="75" cy="75" r="75" fill="#F1F7FA" />
                <path d="M49.0354 72.6442L68.6258 94.6015L103.402 59.2685" stroke="#27AE60" stroke-width="6" />
              </svg>
            </div>
            <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0 }}>Payment made successfully.</h5>
            <p className={styles.text_sm}>Your payment was completed successfully. </p>
            <p className={styles.text_sm}>{sessionStorage.getItem("tranId")} </p>
            <p className={styles.text_sm}>{JSON.parse(sessionStorage.getItem("product_data")).amount} </p>
            <button className={styles.auth_btn} onClick={closePopup}>Close</button>
          </div>
        )
      }{
        tab === 4 && (
          <div>
            <div>
              <svg width="81" height="64" viewBox="0 0 81 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M79.9215 53.4316L47.3887 3.52291C45.951 1.31719 43.3757 0 40.4999 0C37.624 0 35.0488 1.3169 33.611 3.52291L1.07857 53.4316C-0.359522 55.6373 -0.359522 58.2711 1.07857 60.4771C2.51665 62.6831 5.09158 64 7.96743 64H73.0333C75.9088 64 78.4841 62.6831 79.9218 60.4774C81.3596 58.2714 81.3593 55.6376 79.9215 53.4316ZM40.5002 55.8805C37.6451 55.8805 35.3224 53.8232 35.3224 51.2944C35.3224 48.7658 37.6451 46.7085 40.5002 46.7085C43.3553 46.7085 45.678 48.7658 45.678 51.2944C45.6777 53.8235 43.355 55.8805 40.5002 55.8805ZM45.7129 36.2769C45.7129 38.8226 43.3744 40.8939 40.5002 40.8939C37.626 40.8939 35.2875 38.8226 35.2875 36.2769V15.0866C35.2875 13.8788 36.393 12.8996 37.7567 12.8996H43.244C44.608 12.8996 45.7133 13.8788 45.7133 15.0866V36.2769H45.7129Z" fill="#FF6700" />
              </svg>
            </div>
            <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0, marginTop: 12 }}>Transaction Failed.</h5>
            <h5 style={{ fontSize: 16, fontWeight: 600, margin: 0, marginTop: 12, color: "#FF6700" }}>Error: {errMsg}</h5>
            <p className={styles.text_sm}>Error occured while processing your payment, kindly try again or choose a  different payment method.  </p>
            <button className={styles.auth_btn} onClick={closePopup}>Close</button>
          </div>
        )
      }
    </ContentsContainer>
  );
};
