import React from 'react'
import styles from './general.module.css';

export const CardContainer = ({ children }) => {
    return (
        <div className={`${styles.cardcontainer}`}>
            {
                children
            }
        </div>
    )
}
export const SideCardContainer = ({ children,classStyles }) => {
    return (
        <div className={`${styles.side_card_container} ${classStyles}`}>
            {
                children
            }
        </div>
    )
}
export const SideCardcontent = ({ children,classStyles }) => {
    return (
        <div className={`${styles.side_card_content} ${classStyles}`}>
            {
                children
            }
        </div>
    )
}