import React, { useState } from "react";
import { SVGS } from "../../assets/svgs";
import { LightText } from "../lightText/lighttext";
import { NormalText } from "../normalText/normalText";
import styles from "./normal.module.css";
import {
  isValid,
  getCreditCardNameByNumber
} from 'creditcard.js';
import VisaCardLogo from '../../assets/card-type/visa.png';
import MasterCardLogo from '../../assets/card-type/mastercard.png';
import VerveCardLogo from '../../assets/card-type/verve.png';

const { payLogo } = SVGS

export const InputText2 = ({ type = "text", label, placeholder, under = false, pay, underText, onChange }) => {
  const [cardType, setCardType] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberErr, setCardNumberErr] = useState(null);
  const [scheme, setScheme] = useState(null)

  const handleKeyup = () => {
    // onchange()
  }

  const formatInput = (e) => {
    const inputVal = e.target.value.replace(/ /g, ""); //remove all the empty spaces in the input
    let inputNumbersOnly = inputVal.replace(/\D/g, ""); // Get only digits
    if (inputNumbersOnly.length > 19) {
      //If entered value has a length greater than 16 then take only the first 16 digits
      inputNumbersOnly = inputNumbersOnly.substr(0, 19);
    }
    // Get nd array of 4 digits per an element EX: ["4242", "4242", ...]
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" "); // Join all the splits with an empty space
    }
    setCardNumber(spacedNumber); // Set the new CC number
    getCardType(e);
  }

  const getCardType = (e) => {
    const cleanVal = e.target.value.replace(/ /g, "");
    if (cleanVal.length >= 16) {
      if (!isValid(cleanVal)) {
        setCardNumberErr(isValid(cleanVal))
      } else {
        setCardNumberErr(null)
      }
    } else {
      setCardNumberErr(null)
    }
    //console.log(cleanVal)

    switch (getCreditCardNameByNumber(cleanVal).toUpperCase()) {
      case "VISA":
        setCardType(VisaCardLogo);
        break;
      case "MASTERCARD":
        setCardType(MasterCardLogo);
        break;
      case "VERVE":
        setCardType(VerveCardLogo);
        break;
      default:
        setCardType(null);
        break;
    }
    setScheme(getCreditCardNameByNumber(cleanVal));
  }

  return (
    <div className={`${styles.container}`}>
      <span className={styles.inline_label}>{label}</span>
      <div className={styles.input_container}>
        <input
          type={type}
          role="presentation" 
          autoComplete="off"
          onInput={formatInput}
          id="thisInput"
          scheme={scheme}
          value={cardNumber}
          className={`${styles.input} ${styles.input2}`}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={handleKeyup}
        />
        {cardType ? <img
          className={styles.img}
          src={cardType}
          alt="" /> : ''}
      </div>
      {
        cardNumberErr
          ?
          <p className={styles.errorlabel}>{cardNumberErr}</p>
          :
          ''
      }
    </div>
  );
};

export const InputText = ({ type = "text", maxlength, reference, label, placeholder, under = false, pay, underText, onchange, onInput }) => {
  const handleKeyup = () => {
    onchange()
  }
  return (
    <div className={`${styles.container}`}>
      <label htmlFor="thisInput">
        <NormalText>{label}</NormalText>
      </label>
      <div className={styles.input_container}>
        <input
          maxlength={maxlength}
          role="presentation" autoComplete="off"
          ref={reference}
          type={type}
          id="thisInput"
          onInput={onInput}
          className={styles.input}
          placeholder={placeholder}
          onKeyUp={onchange}
        />
        {pay && <img className={styles.img} src={payLogo} alt="" />}
      </div>{
        under && <LightText>{underText}</LightText>}
    </div>
  );
};

export const InputPhone = ({ type = "text", reference, label, placeholder, under = false, pay, underText, onchange, onInput }) => {
  const handleKeyup = () => {
    onchange()
  }
  return (
    <div className={`${styles.container}`}>
      {/* <label htmlFor="thisInput">
        <NormalText>{label}</NormalText>
      </label> */}
      <span className={styles.inline_label}>{label}</span>
      <div className={styles.input_container}>
        <input
          role="presentation" autoComplete="off"
          ref={reference}
          type={type}
          id="thisInput"
          onInput={onInput}
          className={styles.input2}
          placeholder={placeholder}
          onKeyUp={onchange}
          style={{ height: '42px' }}
        />
        {pay && <img className={styles.img} src={payLogo} alt="" />}
      </div>{
        under && <LightText>{underText}</LightText>}
    </div>
  );
};

export const InputSelect = ({ label, placeholder, width, code = true, onchange, values, setSelectedBank }) => {
  const [selected, setSelected] = useState(null);
  return (
    <div style={{ width: width }} className={`${styles.container}`}>
      <label htmlFor="thisInput">
        <NormalText>{label}</NormalText>
      </label>
      <div className={styles.input_container}>
        <select
          type="text"
          id="thisInput"
          className={`${styles.input} ${styles.select}`}
          placeholder={placeholder}
          onChange={onchange}
          onInput={(e)=> {setSelectedBank(e.target.value); setSelected(e.target.value)}}
        >
          <option value="">Select bank</option>
          {
            values?.map(val=>{
              return (
                <option value={val.bankId}>{val?.bankName}</option>
              )
            })
          }
        </select>
        {/* {code && (
          <div className={`${styles.code}`}>
            <code>*{selected?.bankUSSDCode}* - #</code>
          </div>
        )} */}
      </div>
    </div>
  );
};

export const InputCVV = ({ type = "text", label, placeholder, under = false, pay, underText, onChange }) => {
  const [cardCVV, setCardCVV] = useState("");
  const formatInput = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");
    if (inputNumbersOnly.length > 3) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 3);
    }
    setCardCVV(inputNumbersOnly);
  }

  return (
    <div className={`${styles.container}`}>
      <span className={styles.inline_label}>{label}</span>
      <div className={styles.input_container}>
        <input
          role="presentation" autoComplete="off"
          type={type}
          onInput={formatInput}
          id="thisInput"
          value={cardCVV}
          onChange={onChange}
          className={`${styles.input} ${styles.input2}`}
          placeholder={placeholder}
        />
        {pay && <img className={styles.img} src={payLogo} alt="" />}
      </div>{
        under && <LightText>{underText}</LightText>}
    </div>
  );
};

export const InputPin = ({ type = "text", label, placeholder, under = false, pay, underText, onChange }) => {
  const [cardPin, setCardPin] = useState("");
  const formatInput = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");
    if (inputNumbersOnly.length > 4) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 4);
    }
    setCardPin(inputNumbersOnly);
  }
  return (
    <div className={`${styles.container}`}>
      <span className={styles.inline_label}>{label}</span>
      <div className={styles.input_container}>
        <input
          role="presentation" autoComplete="off"
          type={type}
          onInput={formatInput}
          id="thisInput"
          value={cardPin}
          onChange={onChange}
          className={`${styles.input} ${styles.input2}`}
          placeholder={placeholder}
        />
        {pay && <img className={styles.img} src={payLogo} alt="" />}
      </div>{
        under && <LightText>{underText}</LightText>}
    </div>
  );
};
export const InputDate = ({ type = "text", label, placeholder, under = false, pay, underText, onChange }) => {
  // const [formattedData, setFormattedData] = useState("");
  const [cardExpDate, setCardExpDate] = useState("");
  const handleKeyup = () => {
    // onchange()
  }

  const formatInput = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");
    if (inputNumbersOnly.length >= 4) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 4);
    }
    const splits = inputNumbersOnly.match(/.{1,2}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join("/");
    }
    setCardExpDate(spacedNumber);
  }

  return (
    <div className={`${styles.container}`}>
      <span className={styles.inline_label}>{label}</span>
      <div className={styles.input_container}>
        <input
          role="presentation" autoComplete="off"
          type={type}
          onInput={formatInput}
          id="thisInput"
          value={cardExpDate}
          className={`${styles.input} ${styles.input2}`}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={handleKeyup}
        />
        {pay && <img className={styles.img} src={payLogo} alt="" />}
      </div>{
        under && <LightText>{underText}</LightText>}
    </div>
  );
};
