import {
  CARD_PIN,
  ITEM_CARD_EXPIRYDATE,
  ITEM_CARD_CVV,
  ITEM_CARD_HOOLDERS_NAME,
  ITEM_CARD_NUMBER,
} from "../store/types";

const INITIAL_STATE = { pin: "", card: {} };

export const paymentDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARD_PIN:
      return { ...state, pin: action.payload };
    case ITEM_CARD_EXPIRYDATE:
      return { ...state, card: { ...state.card, expiry_date: action.payload } };
    case ITEM_CARD_CVV:
      return { ...state, card: { ...state.card, cvv: action.payload } };
    case ITEM_CARD_HOOLDERS_NAME:
      return { ...state, card: { ...state.card, holder_name: action.payload } };
    case ITEM_CARD_NUMBER:
      return { ...state, card: { ...state.card, card_number: action.payload } };
    default:
      return state;
  }
};
