import React from "react";
import { NormalText } from "../normalText/normalText";
import styles from "./normal.module.css";

const Spinner = () => {
  return <div className="spinner"></div>;
};

export const Button = ({ text, active = false, onClick=()=>{}, loading, type }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <button
      disabled={loading}
      onClick={handleClick}
      className={`${styles.container}`}
      type={type}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export const CancelButton = ({ text, onclick }) => {
  const handleClick = () => {
    onclick();
  };
  return (
    <div onClick={handleClick} className={`${styles.cancel}`}>
      {text}
    </div>
  );
};
