import React from "react";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./payable.module.css";
import LoginToPay from "../../assets/svgs/loginToPay.svg";
import ScanToPay from "../../assets/svgs/scanToPay.svg";

import { SVGS } from "../../assets/svgs";
import { LightText } from "../lightText/lighttext";

const { logo, play_arrow } = SVGS;

const Spinner = () => {
  return (
      <div className="spinner2"></div>
  )
}

export const PayableLogin = ({ title, tabIndex, description,onclick, loading }) => {
  return (
    <div onClick={()=>onclick(tabIndex)} className={`${styles.container}`}>
      <div className={`${styles.logoContainer}`}>
        <img src={LoginToPay} alt="" />
      </div>
      <div className={`${styles.textContainer}`}>
        <BoldText size={14}>{title}</BoldText>
        <LightText size={12}>
          {description}
        </LightText>
      </div>
      <div className={`${styles.play}`} >
       {
         loading ? <Spinner /> : <img src={play_arrow} alt="" />
       }
      </div>
    </div>
  );
};

export const PayableScan = ({ title, tabIndex, description,onclick, loading }) => {
  return (
    <div onClick={()=>onclick(tabIndex)} className={`${styles.container}`}>
      <div className={`${styles.logoContainer}`}>
        <img src={ScanToPay} alt="" />
      </div>
      <div className={`${styles.textContainer}`}>
        <BoldText size={14}>{title}</BoldText>
        <LightText size={12}>
          {description}
        </LightText>
      </div>
      <div className={`${styles.play}`} >
       {
         loading ? <Spinner /> : <img src={play_arrow} alt="" />
       }
      </div>
    </div>
  );
};
