import React from "react";
import Swal from 'sweetalert2';
import { NormalText } from "../normalText/normalText";
import styles from "./normal.module.css";
import { InputText } from "../inputs/inputs";
import { Dispatach_Card_Navigation } from "../../redux/actions/navigations";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { number } from "prop-types";
import config from '../../config.js';
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { hideLoader, showLoader } from "../../utils/loader";


export const Six = ({ text, action, getPin, supportMessage, cardNumber, processor }) => {
  const input_1 = useRef();
  const input_2 = useRef();
  const input_3 = useRef();
  const input_4 = useRef();
  const input_5 = useRef();
  const input_6 = useRef();


  const [input1, setInput1] = useState(null);
  const [input2, setInput2] = useState(null);
  const [input3, setInput3] = useState(null);
  const [input4, setInput4] = useState(null);
  const [input5, setInput5] = useState(null);
  const [input6, setInput6] = useState(null);


  let pin = `${input1}${input2}${input3}${input4}${input5}${input6}`;
  //console.log(pin);
  useEffect(() => {
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }, [pin])
  const SetInput1 = (e) => {
    setInput1(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const SetInput2 = (e) => {
    setInput2(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const SetInput3 = (e) => {
    setInput3(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const SetInput4 = (e) => {
    setInput4(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const SetInput5 = (e) => {
    setInput5(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const SetInput6 = (e) => {
    setInput6(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}${input5}${input6}`);
  }
  const Dispatch = useDispatch();
  const handleChange = (e, newinput, change) => {
    if (e.current.value.length === 1 && !change) {
      newinput.current.focus();
    } else if (change) {
      if(`${input1}${input2}${input3}${input4}${input5}${input6}`.length === 6){
        if(!isNaN(`${input1}${input2}${input3}${input4}${input5}${input6}`) && !isNaN(parseFloat(`${input1}${input2}${input3}${input4}${input5}${input6}`))){
          // Inside here shows the pin is valid number
          //alert("Pin is a valid number");
          Authourize()
          // Dispatach_Card_Navigation(Dispatch, 3);
        }else{
          alert(`${input1}${input2}${input3}${input4}${input5}${input6} : is the pin`);
        }
      }
    }
  };
  useEffect(() => {
    input_1.current.focus();
  }, []);

  const Authourize = () => {
    //setLoading(true);
    showLoader();
    const tranId = sessionStorage.getItem("tranId");
  
    const paymentData = {
      "cardEncrypt": JSON.parse(cardNumber),
      "otp": pin,
      "tranId": tranId
    }
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/processing`;
    axios.post(API_ENDPOINT, paymentData).then(response => {
      // tran ID expired
          //sessionStorage.setItem("expired", true);
      // sessionStorage.removeItem("tranId");
      // sessionStorage.removeItem("product_data");
      //console.log('3ddd', response?.data);
        hideLoader()
      if(response?.data?.status){
       /* //console.log('succ', response);
        Swal.fire('Success', response?.data?.data?.message)
        window.open('redirect')*/
        Swal.fire({
          title: "Success",
          text: `${response?.data?.data?.message}`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            window.open('redirect', '_self')
          }
        });

      
        // toast.success(response?.data?.data?.message)
        Dispatach_Card_Navigation(Dispatch, 1)
      }
      
      hideLoader();
      setTimeout(() => {
        //setLoading(false);
        //window.location.href = response.data.data.callbackUrl;
      }, 3000);
    }).catch(error => {
      //console.log('err', error );
      Swal.fire('Error', error?.response?.data?.message || error?.response?.data?.description)
      toast.error("Error!",error?.response?.data?.message || error?.response?.data?.description)
        hideLoader();
      //  setErrMsg(error.response.data.message);
      //setLoading(false);
    });
  }
  const handleResendOtp = async () =>{
    showLoader();
    const tranId = sessionStorage.getItem("tranId");
  
    const paymentData = {
      "mode": '',
      "transactionId": tranId
    }
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/otp-re-send/${processor}`;
    axios.post(API_ENDPOINT, paymentData).then(response => {    
      //console.log('3ddd', response?.data);
        hideLoader()      
         toast.success(response?.data?.message)
         
        // Dispatach_Card_Navigation(Dispatch, 1)
      }).catch(error => {
      //console.log('err', error );
      // Swal.fire('Error', error?.response?.data?.message || error?.response?.data?.description)
      toast.error("Error!",error?.response?.data?.message || error?.response?.data?.description)

        hideLoader();     
    });  }
  return (
    <>
    <div><Toaster/></div>
    <div className={`${styles.container}`}>
      <div className={`${styles.boxContainer}`}>
        <InputText type="text" maxlength="1" reference={input_1} onInput={SetInput1} onchange={(e) => handleChange(input_1, input_2, false)} />
        <InputText type="text" maxlength="1" reference={input_2} onInput={SetInput2} onchange={(e) => handleChange(input_2, input_3, false)} />
        <InputText type="text" maxlength="1" reference={input_3} onInput={SetInput3} onchange={(e) => handleChange(input_3, input_4, false)} />
        <InputText type="text" maxlength="1" reference={input_4} onInput={SetInput4} onchange={(e) => handleChange(input_4, input_5, false)} />
        <InputText type="text" maxlength="1" reference={input_5} onInput={SetInput5} onchange={(e) => handleChange(input_5, input_6, false)} />
        <InputText type="text" maxlength="1" reference={input_6} onInput={SetInput6} onchange={(e) => handleChange(input_6, input_2, true)} />

      </div>
     
    </div>
    <div style={{textAlign:'center'}}>
        <p style={{textAlign:'center'}}>
        {supportMessage}        
      </p>
      <p>
        Or
      </p>
      <p style={{display:'flex', textAlign:'center', marginTop:40, marginLeft:'20%'}}>
       Click here to <a onClick={handleResendOtp} style={{color:'blue', marginLeft:10}}> resend OTP       </a>
      </p>
    </div>
    </>
  );
};
