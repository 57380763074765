import axios from "axios";
import config from "../config.js";

export const authOTP = (email) =>
  axios
    .post(`${config.API_ROOT_ADDRESS2}/api/v1/auth/generate-login-otp/${email}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err?.response?.data, 'IN_AXIOS')
      throw new Error(err?.response?.data?.message);
    });

export const toggle2FA = (userId) =>
  axios
    .Get(`${config.API_ROOT_ADDRESS2}/api/v1/auth/toggle-2fa?userId=${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

export const check2FA = (userId) =>
  axios
    .Get(`${config.API_ROOT_ADDRESS2}api/v1/auth/status-check-2fa/${userId}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
