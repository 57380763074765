import React from 'react';
import styles from './demo.module.css';

const Spinner = () => {
    return (
        <div className="spinner"></div>
    )
}

function Button({ amount, onClick, active, loading }) {
    return (
        <button className={`
        ${styles.button} 
        ${active ? styles.active : ''}`}
            onClick={onClick}>
            {loading ? <Spinner/> : "Pay"} {amount}
        </button>
    )
}

export default Button
