
import React, { useState, useEffect } from "react";
import { Button, CancelButton } from "../button/button";
import { ContentsContainer } from "../card/cardcontainer";
import { InputSelect } from "../inputs/inputs";
import { LightText } from "../lightText/lighttext";
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./ussd.module.css";
import { BiCopy } from "react-icons/bi";
import axios from 'axios';
import { Toaster, toast } from "react-hot-toast";
import Select from 'react-select';
import config from '../../config.js';
import {useNavigate} from "react-router-dom";


const Spinner = () => {
  return (
    <div style={{display: "flex", justifyContent: "center", paddingTop: "24px"}}>
      <div className="spinner" style={{borderColor: "red", borderTop: "transparent"}}></div>
      <span>Loading...</span>
    </div>
  )
}

export const Ussd = ({ title }) => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [banks, setBanks]= useState([]);
  const [bankCode, setBankCode] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(()=>{
    if(sessionStorage.getItem("expired")){
      const fallbackUrl = sessionStorage.getItem("fallbackUrl");
      sessionStorage.clear();
      if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
        window.location.href= fallbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }else{
      axios.get(`${config.API_ROOT}/ussd-service/api/v2/bank/fetch-all`).then((response) => {
        // alert(JSON.stringify(response.data.data))
        const tempBank = response.data.data.map(bank=> ({
          label: bank.bankName,
          value: bank.bankId
        }));
        setBanks(tempBank);
      }).catch((err) => {
         toast("Fetching banks failed.", {
            backgroundColor: '#FF6700',
            color: '#ffffff',
          })
      });
    }
  },[])

  const handleChange = (e) => {
    const productDetails = JSON.parse(sessionStorage.getItem("product_data"));
    const customer = productDetails.customer;
    const transactionRef = Date.now()+Math.floor((Math.random()*9));
    const payload = {
      amount: productDetails.amount,
      bankId: e.value,
      channel: "USSD",
      customerEmail: customer.email,
      customerId: customer.customerId,
      merchantId: customer.merchantId,
      paymentDescription: productDetails.description,
      subMerchantName: productDetails.merchant_business_name,
      transactionReference: transactionRef,
      transactionType: 0,
      transactionRefNo: sessionStorage.getItem("tranId")
    }
    setIsloading(true);
    axios.post(`${config.API_ROOT}/ussd-service/api/v2/transaction`, payload).then((response) => {
      setBankCode(response?.data?.data?.responseDetails?.ussdString);
      setTabs(2);
      // tran id expired
      sessionStorage.setItem("expired", true);
    }).catch((err) => {
       toast("Transaction Failed", {
          backgroundColor: '#FF6700',
          color: '#ffffff',
        })
    });
  };
  const CancelHandler = () => {
    setTabs(1);
  }
  const CopyCode = () => {
    navigator.clipboard.writeText(bankCode);
    toast("Code copied", {
      backgroundColor: '#FF6700',
      color: '#ffffff',
    });
  }

  const checkPaymentStatus = () => {
    const tranId = sessionStorage.getItem("tranId");
    const ENDPOINT = `${config.API_ROOT}/ussd-service/api/v2/transaction/query-ussd-transaction/${tranId}`;
    axios.get(ENDPOINT, null).then(response => {
      // //console.log(response);
      if(response.data.details[0].includes("pending")){
        toast.error(response.data.details[0])
      }else{
        navigate("/status");
      }
    }).catch(error=>{
      toast.error(error.response.data.details[0]);
      window.location.reload();
    });
    // if (transactionStatus) {
    //   // setTab(3);
    //   // const payload = {
    //   //   status: "SUCCESSFUL"
    //   // }
    //   const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
    //   axios.put(ENDPOINT, null).then(response => {
    //     // //console.log(response);
    //     navigate("/status");
    //   });
    // } else {
    //   // setTab(4);
    //   setErrMsg("Payment not completed");
    //   const tranId = sessionStorage.getItem("tranId");
    //   // const payload = {
    //   //   status: "FAILED"
    //   // }
    //   const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
    //   axios.put(ENDPOINT, null).then(response => {
    //     // //console.log(response);
    //     navigate("/status");
    //   });
    // }
  }

  return (
    <ContentsContainer>
    <Toaster />
      {tabs === 1 && (
        <div className={styles.contents}>
          <BoldText>
            Pay by dailing USSD Code on your mobile device
          </BoldText>
          <div className={styles.inputContainer}>
            <Select placeholder={<p style={{margin: 0}}>Choose your bank to start the payment</p>} options={banks} onChange={handleChange} />
          </div>
          {
            isloading && <Spinner />
          }
        </div>
      )}

      {tabs === 2 && (
        <div className={styles.contentsBetween}>
          <div className={styles.text}>
            <LightText align="center">
              Copy the following code below to your mobile device and dial to
              complete this transaction with GTBank
            </LightText>
          </div>
          <br/>
          <BoldText>
            {bankCode}{" "}
            <span style={{cursor: "pointer"}} onClick={CopyCode}>
              <BiCopy color="#C4C4C4" />
            </span>
          </BoldText>
          <br/>
          <div className={styles.buttonContainer}>
            <Button
              onClick={checkPaymentStatus}
              text="Click here if you’ve complete payment"
              active={true}
            />
            <CancelButton text="cancel" onclick={CancelHandler}/>
          </div>
        </div>
      )}{
        tabs === 3 && (
          <div>
            <div>
              <svg width="100" height="100" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="75" cy="75" r="75" fill="#F1F7FA" />
                <path d="M49.0354 72.6442L68.6258 94.6015L103.402 59.2685" stroke="#27AE60" stroke-width="6" />
              </svg>
            </div>
            <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0 }}>Payment made successfully.</h5>
            <p className={styles.text_sm}>Your payment was completed successfully. </p>
            <p className={styles.text_sm}>{sessionStorage.getItem("tranId")} </p>
            <p className={styles.text_sm}>{JSON.parse(sessionStorage.getItem("product_data")).amount} </p>
            <button className={styles.auth_btn} onClick={CancelHandler}>Close</button>
          </div>
        )
      }{
        tabs === 4 && (
          <div>
            <div>
              <svg width="81" height="64" viewBox="0 0 81 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M79.9215 53.4316L47.3887 3.52291C45.951 1.31719 43.3757 0 40.4999 0C37.624 0 35.0488 1.3169 33.611 3.52291L1.07857 53.4316C-0.359522 55.6373 -0.359522 58.2711 1.07857 60.4771C2.51665 62.6831 5.09158 64 7.96743 64H73.0333C75.9088 64 78.4841 62.6831 79.9218 60.4774C81.3596 58.2714 81.3593 55.6376 79.9215 53.4316ZM40.5002 55.8805C37.6451 55.8805 35.3224 53.8232 35.3224 51.2944C35.3224 48.7658 37.6451 46.7085 40.5002 46.7085C43.3553 46.7085 45.678 48.7658 45.678 51.2944C45.6777 53.8235 43.355 55.8805 40.5002 55.8805ZM45.7129 36.2769C45.7129 38.8226 43.3744 40.8939 40.5002 40.8939C37.626 40.8939 35.2875 38.8226 35.2875 36.2769V15.0866C35.2875 13.8788 36.393 12.8996 37.7567 12.8996H43.244C44.608 12.8996 45.7133 13.8788 45.7133 15.0866V36.2769H45.7129Z" fill="#FF6700" />
              </svg>
            </div>
            <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0, marginTop: 12 }}>Transaction Failed.</h5>
            {/* <h5 style={{ fontSize: 16, fontWeight: 600, margin: 0, marginTop: 12, color: "#FF6700" }}>Error: {errMsg}</h5> */}
            <p className={styles.text_sm}>Error occured while processing your payment, kindly try again or choose a  different payment method.  </p>
            <button className={styles.auth_btn} onClick={CancelHandler}>Close</button>
          </div>
        )
      }
    </ContentsContainer>
  );
};
