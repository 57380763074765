import React, { useEffect, useRef } from "react";

const Tiframe =({tDData}) => {
    
    let form=useRef();
    useEffect(()=>{
      form.current.submit();
    }, []);
    return(       
        <form  ref={form} id="form1" action={tDData?.ACSUrl}  method="post">
        <input name="JWT" hidden value={tDData?.jwt}/>
        <input name="MD" hidden value={tDData?.MD}/>
        <div style={{display:'flex',  right: '40%', marginTop:'40%', textAlign: 'center', justifyContent:'center'}}>
        <button type='submit'>Continue</button>
        </div>
      </form>
    )
}

export default Tiframe;