import { CURRENT_TAB } from "../store/types";


export const Dispatach_New_Tab = (dispatch,payload)=> {

  dispatch({
    type: CURRENT_TAB,
    payload: payload
  });
};
;