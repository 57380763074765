import { BATCH_DELETE,CURRENT_TAB, STORE_TO_ACCOUNT } from "../store/types";

const INITIAL_STATE = "Card";

export const tabsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CURRENT_TAB:
      return action.payload
    default:
      return state;
  }
};
