import React from 'react'
import styles from './background.module.css';


export const Background = ({ children }) => {
    return (
        <div className={`${styles.Background}`}>
            <div className={`${styles.nav}`}>
            </div>
            { children }
        
        </div>
    )
}
