import React from 'react';
import styles from './demo.module.css';
import  logo from '../../assets/newLogo.png'

function FormHead() {
    return (
        <div className={styles.formHead}>
            <img src={logo} width='150px' />
            <h2 className={styles.title}>WayaQuick Checkout Demo</h2>
            <h5 className={styles.subTitle}>The easiest way to try <b>WayaQuick</b> is to make a live payment. Choose a small amount that you can afford to give away. <b>Please note</b> that this is a <b>real transaction</b>, and that your account will be <b>debited</b>.</h5>
        </div>
    )
}

export default FormHead;
